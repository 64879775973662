import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    Home as HomePage,
    NotFound as NotFoundPage,
    AppInfo as AppInfoPage,
    Assess8Instrument as Assess8InstrumentPage,
    Level3 as Level3Page,
    Level6 as Level6Page,
    Level7 as Level7Page,
    Cat4 as Cat4Page,
    Cat11 as Cat11Page,
    Cat108 as Cat108Page,
    Assess8_translations as Assess8_translationsPage,
    Level3_translations as Level3_translationsPage,
    Level6_translations as Level6_translationsPage,
    OrganizationsAccounts as OrganizationsAccountsPage,
    OrganizationsOrders as OrganizationsOrdersPage,
    OrganizationsAll_Areas as OrganizationsAll_AreasPage,
    OrganizationsAll_Badges as OrganizationsAll_BadgesPage,
    OrganizationsAll_Packages as OrganizationsAll_PackagesPage,
    OrganizationsArea_UI_Conf as OrganizationsArea_UI_ConfPage,
    OrganizationsBadge_UI_Conf as OrganizationsBadge_UI_ConfPage,
    OrganizationsConfig as OrganizationsConfigPage,
    OrganizationsList as OrganizationsListPage,
    OrganizationsSubscriptions as OrganizationsSubscriptionsPage,
    Packages as PackagesPage,
    SettingsConfigs as SettingsConfigsPage,
    SettingsBuilds as SettingsBuildsPage,
    UsersList as UsersListPage,
} from './pages';

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/home"
            />
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path="/register"
            />
            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path="/account/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path="/auth/reset/password"
            />


            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path="/auth/confirm/email"
            />
            <PrivateRouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                path="/home"
            />
            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path="/password/change"
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MainLayout}
                path="/logout"
            />

            {/*########################*/}
            {/*### META DATA -- ROUTES ###*/}
            {/*########################*/}

            <PrivateRouteWithLayout
                component={Assess8InstrumentPage}
                exact
                layout={MainLayout}
                path="/meta_data/assess8/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level3Page}
                exact
                layout={MainLayout}
                path="/meta_data/level3/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level6Page}
                exact
                layout={MainLayout}
                path="/meta_data/level6/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level7Page}
                exact
                layout={MainLayout}
                path="/meta_data/level7/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat4Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat4/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat11Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat11/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat108Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat108/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={PackagesPage}
                exact
                layout={MainLayout}
                path="/meta_data/packages/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Assess8_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/assess8_translation/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level3_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/level3_translation/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level6_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/level6_translation/:url_param_str"
            />


            {/*########################*/}
            {/*### ORGANIZATIONS -- ROUTES ###*/}
            {/*########################*/}
            <PrivateRouteWithLayout
                component={OrganizationsAccountsPage}
                exact
                layout={MainLayout}
                path="/org/accounts"
            />

            <PrivateRouteWithLayout
                component={OrganizationsOrdersPage}
                exact
                layout={MainLayout}
                path="/org/orders"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_AreasPage}
                exact
                layout={MainLayout}
                path="/org/all_areas"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_BadgesPage}
                exact
                layout={MainLayout}
                path="/org/all_badges"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_PackagesPage}
                exact
                layout={MainLayout}
                path="/org/all_packages"
            />

            <PrivateRouteWithLayout
                component={OrganizationsArea_UI_ConfPage}
                exact
                layout={MainLayout}
                path="/org/area_ui_conf"
            />

            <PrivateRouteWithLayout
                component={OrganizationsBadge_UI_ConfPage}
                exact
                layout={MainLayout}
                path="/org/badge_ui_conf"
            />

            <PrivateRouteWithLayout
                component={OrganizationsConfigPage}
                exact
                layout={MainLayout}
                path="/org/configs"
            />

            <PrivateRouteWithLayout
                component={OrganizationsListPage}
                exact
                layout={MainLayout}
                path="/org/list"
            />

            <PrivateRouteWithLayout
                component={OrganizationsSubscriptionsPage}
                exact
                layout={MainLayout}
                path="/org/subscriptions"
            />



            {/*########################*/}
            {/*### Miscellaneous -- ROUTES ###*/}
            {/*########################*/}

            <PrivateRouteWithLayout
                component={SettingsConfigsPage}
                exact
                layout={MainLayout}
                path="/settings/configs"
            />

            <PrivateRouteWithLayout
                component={SettingsBuildsPage}
                exact
                layout={MainLayout}
                path="/settings/builds"
            />

            <PrivateRouteWithLayout
                component={UsersListPage}
                exact
                layout={MainLayout}
                path="/users/list"
            />

            <RouteWithLayout
                component={AppInfoPage}
                exact={false}
                layout={MinimalLayout}
                path="/app_info"
            />

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />

        </Switch>
    );
};

export default Routes;
