import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";


export const listAllUsers = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    return invokeApi({
        path   : '/admin/users/list',
        method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
    });
}

export const updateUserEntity = (member_id, is_active) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/users/update/entity',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        member_id,
        is_active
    };

    return invokeApi(requestObj);
}

export const addNewUser = (email_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/users/add',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        email_id
    };

    return invokeApi(requestObj);
}

export const removeAdminPortalUser = (member_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/users/remove',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        member_id
    };

    return invokeApi(requestObj);
}

export const updateUserPermissions = (member_id, permissions) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/users/update/permissions',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        member_id,
        ...permissions
    };

    return invokeApi(requestObj);
}