import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { withRouter } from "react-router";

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {confirmEmail, logoutUser, resendEmailConfirmation} from '../../../services/auth.service';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {store} from "react-notifications-component";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ConfirmEmail = (props) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [resendLinkEnable, setResendLinkEnable] = useState(false)

    const { setAuthToken, setAuthUser } = useAuth();

    useEffect( ()=> {
        setTimeout( () => {
            setResendLinkEnable(true);
        }, 9000)
    }, [] )

    const formik = useFormik({
        initialValues: {
            ver_code: ''
        },
        onSubmit: async (values) => {
            setLoading(true);

            console.log('Form data => ', values);
            try {
                const result = await confirmEmail(values.ver_code);
                console.log('result.data -> ', result.data);

                if (result.data && result.data.user) {
                    setAuthUser(result.data.user)
                }

                store.addNotification({
                    title: "Confirmed",
                    message: "Successfully Confirmed the Email Address",
                    type: "success",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
                // redirect to login page here
                props.history.push('/home');
            } catch (e) {
                // already toasted the error
            }

            setLoading(false)
        }
    });

    const handleLogout = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            // history.push('/login');
        } catch (e) {
            // already toasted the error
        }
    };

    const resendVerificationCode = async () => {

        setLoading(true);

        try {
            const result = await resendEmailConfirmation();
            console.log('result.data -> ', result.data);

            store.addNotification({
                title: "Code Resent",
                message: "Successfully sent code to your email address",
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2000,
                    showIcon: true
                }
            });
        }
        catch (e) {
            // already toasted the error
        }

        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Enter Verification-Code
                </Typography>
                <Typography >
                    please check your email, token has been sent!
                </Typography>
                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="ver_code"
                        label="Verification Code"
                        name="ver_code"
                        autoComplete="ver_code"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.ver_code}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Submit
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Link onClick={handleLogout} variant="body2">
                                Logout
                            </Link>
                        </Grid>
                        {
                            resendLinkEnable && <Grid item>
                                <Link onClick={resendVerificationCode} variant="body2">
                                    {"Resend Code?"}
                                </Link>
                            </Grid>
                        }
                    </Grid>

                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default withRouter(ConfirmEmail);