import React, {useRef, useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";

import { MDBDataTable } from 'mdbreact';

import { ExcelRenderer } from '../../../utils/excel_parser';
import Button from "@material-ui/core/Button";

import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {toastWarning} from "../../../utils/utils";
import ImportPreviewModal from "./ImportPreviewModal.comp";
import EditEntityModal from "./EditEntityModal.comp";
import PreRemoveModal from "./PreRemoveModal.comp";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    btnStyle: {
        marginLeft: '10px'
    }
}));



export default function DisplayMetaWithImport(props) {

    const {title, modal_title, sheet_name, columns, tableCols, jsonSpRender, tableSpRender, search_query,
        listAPI, postAPI, deleteAPI, updateAPI, preRemoveAPI, populate_locale} = props

    const classes = useStyles();

    const [openDataPreview, setOpenDataPreview] = useState(false);
    const [previewCols, setPreviewCols] = useState([]);
    const [previewRows, setPreviewRows] = useState([]);

    const [openEditModal, setOpenEntityModal] = useState(false);
    const [editableRow, setEditableRow] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);

    const [openPreRemoveModal, setOpenPreRemoveModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState(undefined);

    const [excelDataJson, setExcelDataJson] = useState({});

    const fileInput = useRef();

    useEffect( () => {
        setLoading(true);
        loadTableData().then(d => setLoading(false)).catch(e => setLoading(false));
    }, []);

    const renderUpdateEntityModal = (row) => {

        let formValues = {};
        for (let col of columns) {
            formValues[col.json_key] = row ? row[col.data_key] : '';
        }

        setFormValues(formValues)
        setEditableRow(row);
        setOpenEntityModal(true);
    }

    const renderDeleteEntityModal = async (row) => {

        setEditableRow(row)
        if (preRemoveAPI) {
            setOpenPreRemoveModal(true);
            setEditableRow(row);
        } else {
            await deleteAPIHandler(row.id);
        }

    }

    const prepareAndSetTableData = (list_data) => {
        let data_rows = [];
        for (let [index, row] of list_data.entries()) {
            if (tableSpRender) {
                row = tableSpRender(row);
            }
            if (deleteAPI || updateAPI) {
                row.actions = <div className={'meta-data-table-action-container'}>
                    { updateAPI && <EditIcon
                        onClick={()=>{
                            renderUpdateEntityModal(row);
                        }}
                        className={'meta-data-table-action-btn'} fontSize={'large'} color={'primary'}/> }
                    { deleteAPI && <DeleteIcon
                        onClick={()=> {
                            renderDeleteEntityModal(row);
                        }}
                        className={'meta-data-table-action-btn'} fontSize={'large'} color={'error'}/> }
                </div>;
            }

            row['__sr#'] = index+1;
            data_rows.push(row);
        }

        setTableData({
            columns: [
                {
                    label: 'Sr.#',
                    field: '__sr#', sort: 'asc', width: 100
                },
                ...tableCols
            ],
            rows: data_rows
        })
    }

    const loadTableData = async (new_list) => {

        if (new_list) {
            prepareAndSetTableData(new_list)
        } else {

            try {
                const result = await listAPI();
                if (result.data && result.data.metaData) {
                    // populate table data
                    prepareAndSetTableData(result.data.metaData);
                } else {
                    // toast some appropriate error
                }
            } catch (e) {
                // error toasted already
            }

        }

    }

    const updateAPIHandler = async (data) => {
        setFormValues(undefined);
        setLoading(true);

        const id = editableRow.id;
        try {
            let json_data = data;
            for (let col of columns) {
                if (col.data_parser) {
                    json_data[col.json_key] = col.data_parser(json_data[col.json_key]);
                }
            }
            if (jsonSpRender) {
                json_data = jsonSpRender(json_data);
            }

            const res = await updateAPI(id, json_data);

            const table_cols = tableData.columns;
            let table_rows = tableData.rows.map(row=>{
                if (row.id === id ) {
                    let new_row = res.data.entity;
                    if (tableSpRender) {
                        new_row = tableSpRender(new_row);
                    }
                    if (deleteAPI || updateAPI) {
                        new_row.actions = <div className={'meta-data-table-action-container'}>
                            { updateAPI && <EditIcon
                                onClick={()=>{
                                    renderUpdateEntityModal(new_row);
                                }}
                                className={'meta-data-table-action-btn'} fontSize={'large'} color={'primary'}/> }
                            { deleteAPI && <DeleteIcon
                                onClick={()=> {
                                    renderDeleteEntityModal(new_row);
                                }}
                                className={'meta-data-table-action-btn'} fontSize={'large'} color={'error'}/> }
                        </div>;
                    }
                    return new_row
                } else {
                    return row
                }
            });

            setTableData({
                columns: table_cols,
                rows: [...table_rows]
            })

            setLoading(false);
        } catch (e) {
            // error toasted already
            setLoading(false);
        }


        setEditableRow(undefined);
        setOpenEntityModal(false);
    }

    const deleteAPIHandler = async (id) => {
        setLoading(true);
        try {
            const res = await deleteAPI(id);
            console.log('deletion response --> ', res.data);

            if (res.data.removed) {
                const table_cols = tableData.columns;
                let table_rows = tableData.rows.filter(row => row.id !== id);
                setTableData({
                    columns: table_cols,
                    rows: [...table_rows]
                })
            }

            setLoading(false);
        } catch (e) {
            // error toasted already
            setLoading(false);
        }

        setEditableRow(undefined);
        setOpenPreRemoveModal(false);
    }

    const renderFile = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, sheet_name, (err, resp) => {
            if(err){
                console.log(err);
            }
            else{
                let locale = '';
                if (populate_locale) {
                    try {
                        const f_parts = fileObj.name.split('.');
                        locale = f_parts[0].split('_')[1]
                    } catch (e) {
                        console.log('failed to extract the locale from filename -> ', fileObj.name);
                    }
                }

                let rows = [];
                for (let i=1; i<resp.rows.length; i++) {
                    if (resp.rows[i].length > 0 && resp.rows[i][0] !== '') {
                        let row = [];

                        for (let col of columns) {
                            let colData = '';
                            if (resp.rows[i][col.key]) {
                                if (col.data_parser) {
                                    colData = col.data_parser(resp.rows[i][col.key]);
                                } else {
                                    if (typeof resp.rows[i][col.key] === 'string') {
                                        colData = resp.rows[i][col.key].trim();
                                    } else {
                                        colData = resp.rows[i][col.key];
                                    }
                                }
                            }

                            row.push(colData);
                        }
                        if (populate_locale) {
                            row[1] = locale;
                        }

                        rows.push(row);
                    }
                }

                setPreviewCols(columns);
                setPreviewRows(rows);

                // prepare data for json to post of server
                let data = [];
                for (let row of rows) {
                    let row_obj = {};
                    for (const [ci, col] of columns.entries()) {
                        row_obj[col.json_key] = row[ci]
                    }
                    if (jsonSpRender) {
                        row_obj = jsonSpRender(row_obj);
                    }
                    data.push(row_obj);
                }
                setExcelDataJson(data);

                setOpenDataPreview(true);
                setLoading(false);
            }
        }).then(rs=>console.log('finished processing excel file ==> rs => ', rs));
    }

    const fileHandler = (event) => {
        if(event.target.files.length){
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            setLoading(true);

            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if(['xlsx', 'csv'].includes(fileName.slice(fileName.lastIndexOf('.')+1))){
                renderFile(fileObj)
            }
            else{
                setLoading(false);
                toastWarning('invalid file type! please upload .xlsx/.csv file only');
            }
        }
    }

    const openFileBrowser = () => {
        fileInput.current.click();
    }

    const reloadPageData = () => {
        setLoading(true);
        loadTableData().then(d => setLoading(false)).catch(e => setLoading(false));
    }

    return (
        <Container component="main">

            <div className={classes.headerInputGroup}>
                <Button onClick={reloadPageData} variant="contained"  color="primary"
                        className={classes.btnStyle}>Reload</Button>
                <Button onClick={openFileBrowser} variant="contained"  color="primary"
                className={classes.btnStyle}>Import Data</Button>
                <input type="file" hidden onChange={fileHandler} ref={fileInput} onClick={(event)=> { event.target.value = null }} style={{"padding":"10px"}} />
            </div>


            <div className={classes.paper}>
                <h3>{title}</h3>
                <div style={{
                    // position: 'relative',
                    maxWidth: `80vw`
                }}>
                    {
                        tableData && <MDBDataTable
                            exportToCSV
                            striped
                            bordered
                            small
                            searchValue={search_query}
                            data={tableData}
                        />
                    }
                </div>

            </div>

            <ImportPreviewModal
                open={openDataPreview}
                rows={previewRows}
                cols={previewCols}
                handleClose={ ()=> setOpenDataPreview(false) }
                dataJson={excelDataJson}
                tableReload={loadTableData}
                title={modal_title}
                postAPI={postAPI}
            />

            {
                formValues && <EditEntityModal
                    open={openEditModal}
                    columns={columns}
                    formValues={formValues}
                    title={'Editing ID: ' + (editableRow ? editableRow.id : '')}
                    handleClose={ ()=> {
                        setOpenEntityModal(false);
                        setFormValues(undefined);
                        setEditableRow(undefined);
                    } }
                    updateAPI={updateAPIHandler}
                />
            }

            <PreRemoveModal
                open={openPreRemoveModal}
                title={'Deleting : ' + (editableRow ? editableRow.code : '<N/A>')}
                t_id={editableRow ? editableRow.id : -1}
                deleteAPI={deleteAPIHandler}
                preRemoveAPI={preRemoveAPI}
                handleClose={()=>{
                    setOpenPreRemoveModal(false);
                    setEditableRow(undefined);
                }}
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}
