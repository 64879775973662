import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WebIcon from '@material-ui/icons/Web';
import List from '@material-ui/core/List';
import ExistToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import PeopleIcon from '@material-ui/icons/People';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import BusinessIcon from '@material-ui/icons/Business';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import InventoryIcon from '@material-ui/icons/LocalAtm';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';

import { withRouter } from "react-router";

import lvl3_sb_icon from '../../../assets/images/sidebar-icons/lvl3-sb-ico.png';
import lvl6_sb_icon from '../../../assets/images/sidebar-icons/lvl6-sb-ico.png';
import a8_sb_icon from '../../../assets/images/sidebar-icons/a8-sb-ico.png';
import c4_sb_icon from '../../../assets/images/sidebar-icons/c4-sb-ico.png';
import c11_sb_icon from '../../../assets/images/sidebar-icons/c11-sb-ico.png';
import c108_sb_icon from '../../../assets/images/sidebar-icons/c108-sb-ico.png';
import lvl7_sb_icon from '../../../assets/images/sidebar-icons/lvl7-sb-ico.png';

import org_ml3_ico from '../../../assets/images/sidebar-icons/org-ml3-ico.png';
import org_ml6_ico from '../../../assets/images/sidebar-icons/org-ml6-ico.png';
import org_ul3_ico from '../../../assets/images/sidebar-icons/org-ul3-ico.png';
import org_ul6_ico from '../../../assets/images/sidebar-icons/org-ul6-ico.png';
import org_sub_ico from '../../../assets/images/sidebar-icons/org-sub-ico.png';
import {useAuth} from "../../../context/auth";
import {NESTED_ACL_KEYS, SECTION_ACL_KEYS, USER_ACCESS_LEVEL} from "../../../utils/constants";


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const MainList = (props) => {

    const { history, path, logoutAction } = props;

    const classes = useStyles();

    const { authUser } = useAuth();

    const [uiConfOpen, setUiConfOpen] = React.useState(false);
    const [metaDataOpen, setMetaDataOpen] = React.useState(false);
    const [orgMenuOpen, setOrgMenuOpen] = React.useState(false);
    const [packageMenuOpen, setPackageMenuOpen] = React.useState(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);

    const shouldRenderSection = (key) => {
        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                return !!perms[key];
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const showMetaDataSection = () => {
        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                let show = false;
                const all_metadata_perms = [
                    SECTION_ACL_KEYS.META_DATA_ASSESS8,
                    SECTION_ACL_KEYS.META_DATA_CAT4,
                    SECTION_ACL_KEYS.META_DATA_CAT11,
                    SECTION_ACL_KEYS.META_DATA_CAT108,
                    SECTION_ACL_KEYS.META_DATA_LEVEL3,
                    SECTION_ACL_KEYS.META_DATA_LEVEL6,
                    SECTION_ACL_KEYS.META_DATA_LEVEL7,
                    SECTION_ACL_KEYS.META_DATA_PACKAGES
                ];
                const perms = authUser.permissions;
                for (let perm of all_metadata_perms) {
                    if (perms[perm]) {
                        show = true
                    }
                }
                return show;
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const showUserSection = () => {
        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                let show = false;
                const all_metadata_perms = [
                    SECTION_ACL_KEYS.USERS_LIST,
                ];
                const perms = authUser.permissions;
                for (let perm of all_metadata_perms) {
                    if (perms[perm]) {
                        show = true
                    }
                }
                return show;
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const showOrgSection = () => {
        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                let show = false;
                const all_metadata_perms = [
                    SECTION_ACL_KEYS.ORG_CRUD_LIST,
                    SECTION_ACL_KEYS.ORG_ACCOUNTS_LIST,
                    SECTION_ACL_KEYS.ORG_UI_CONFIG_AREA,
                    SECTION_ACL_KEYS.ORG_UI_CONFIG_BADGE,
                    SECTION_ACL_KEYS.ORG_META_MAPPING_AREA,
                    SECTION_ACL_KEYS.ORG_META_MAPPING_BADGE,
                    SECTION_ACL_KEYS.ORG_META_MAPPING_PACKAGE,
                ];
                const perms = authUser.permissions;
                for (let perm of all_metadata_perms) {
                    if (perms[perm]) {
                        show = true
                    }
                }
                return show;
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const metaDataClick = () => {
        setMetaDataOpen(!metaDataOpen);
    }

    const orgMenuClick = () => {
        setOrgMenuOpen(!orgMenuOpen);
    }

    const settingsMenuClick = () => {
        setSettingsMenuOpen(!settingsMenuOpen);
    }

    const usersMenuClick = () => {
        setUserMenuOpen(!userMenuOpen);
    }

    return (
        <List>
            <ListItem selected={path === 'home'} button onClick={()=>history.push('/home')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>


            {
                showOrgSection() && <ListItem button onClick={orgMenuClick}>
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                    {orgMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            }
            <Collapse in={orgMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_CRUD_LIST) && <ListItem selected={path === 'org__list'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/list')}>
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText primary="List" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_ACCOUNTS_LIST) && <ListItem selected={path === 'org__accounts'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/accounts')}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Accounts" />
                        </ListItem>
                    }
                    <ListItem selected={path === 'org__orders'} button className={classes.nested}
                              onClick={()=>history.push('/org/orders')}>
                        <ListItemIcon>
                            <InventoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                    </ListItem>
                    <ListItem selected={path === 'org__configs'} button className={classes.nested}
                              onClick={()=>history.push('/org/configs')}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configs" />
                    </ListItem>
                    <ListItem selected={path === 'org__subscriptions'} button className={classes.nested}
                              onClick={()=>history.push('/org/subscriptions')}>
                        <ListItemIcon>
                            <img src={org_sub_ico} width={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Subscriptions" />
                    </ListItem>
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_UI_CONFIG_AREA) && <ListItem selected={path === 'org__area_ui_conf'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/area_ui_conf')}>
                            <ListItemIcon>
                                <img src={org_ul3_ico} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Module UI Conf" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_UI_CONFIG_BADGE) && <ListItem selected={path === 'org__badge_ui_conf'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/badge_ui_conf')}>
                            <ListItemIcon>
                                <img src={org_ul6_ico} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Badge UI Conf" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_META_MAPPING_AREA) && <ListItem selected={path === 'org__all_areas'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/all_areas')}>
                            <ListItemIcon>
                                <img src={org_ml3_ico} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="All Modules" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_META_MAPPING_BADGE) && <ListItem selected={path === 'org__all_badges'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/all_badges')}>
                            <ListItemIcon>
                                <img src={org_ml6_ico} width={24} />
                            </ListItemIcon>
                            <ListItemText primary="All Badges" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.ORG_META_MAPPING_PACKAGE) && <ListItem selected={path === 'org__all_packages'} button className={classes.nested}
                                                           onClick={()=>history.push('/org/all_packages')}>
                            <ListItemIcon>
                                <LocalOfferIcon />
                            </ListItemIcon>
                            <ListItemText primary="All Packages" />
                        </ListItem>
                    }
                </List>
            </Collapse>


            {
                showUserSection() && <ListItem button onClick={usersMenuClick}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                    {userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            }
            <Collapse in={userMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.USERS_LIST) && <ListItem selected={path === 'users__list'} button className={classes.nested}
                                                           onClick={()=>history.push('/users/list')}>
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText primary="List" />
                        </ListItem>
                    }
                </List>
            </Collapse>

            {
                showMetaDataSection() && <ListItem button onClick={metaDataClick}>
                    <ListItemIcon>
                        <ViewQuiltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Meta-Data" />
                    {metaDataOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            }
            <Collapse in={metaDataOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_ASSESS8) && <ListItem selected={path === 'meta_data__assess8'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/assess8/q=')}>
                            <ListItemIcon>
                                <img src={a8_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Assess 8 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_CAT4) && <ListItem selected={path === 'meta_data__cat4'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/cat4/q=')}>
                            <ListItemIcon>
                                <img src={c4_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Category 4 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_CAT11) && <ListItem selected={path === 'meta_data__cat11'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/cat11/q=')}>
                            <ListItemIcon>
                                <img src={c11_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Category 11 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_CAT108) && <ListItem selected={path === 'meta_data__cat108'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/cat108/q=')}>
                            <ListItemIcon>
                                <img src={c108_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Category 108 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_LEVEL3) && <ListItem selected={path === 'meta_data__level3'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/level3/q=')}>
                            <ListItemIcon>
                                <img src={lvl3_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Level 5 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_LEVEL6) && <ListItem selected={path === 'meta_data__level6'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/level6/q=')}>
                            <ListItemIcon>
                                <img src={lvl6_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Level 6 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_LEVEL7) && <ListItem selected={path === 'meta_data__level7'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/level7/q=')}>
                            <ListItemIcon>
                                <img src={lvl7_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Level 7 - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_PACKAGES) && <ListItem selected={path === 'meta_data__packages'} button className={classes.nested}
                                                           onClick={()=>history.push('/meta_data/packages/q=')}>
                            <ListItemIcon>
                                <LocalOfferIcon />
                            </ListItemIcon>
                            <ListItemText primary="Packages - Data" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_ASSESS8) && <ListItem selected={path === 'meta_data__assess8_translation'} button className={classes.nested}
                                                                                             onClick={()=>history.push('/meta_data/assess8_translation/q=')}>
                            <ListItemIcon>
                                <img src={a8_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Assess 8 - Translations" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_LEVEL3) && <ListItem selected={path === 'meta_data__level3_translation'} button className={classes.nested}
                                                                                            onClick={()=>history.push('/meta_data/level3_translation/q=')}>
                            <ListItemIcon>
                                <img src={lvl3_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Level 5 - Translations" />
                        </ListItem>
                    }
                    {
                        shouldRenderSection(SECTION_ACL_KEYS.META_DATA_LEVEL6) && <ListItem selected={path === 'meta_data__level6_translation'} button className={classes.nested}
                                                                                            onClick={()=>history.push('/meta_data/level6_translation/q=')}>
                            <ListItemIcon>
                                <img src={lvl6_sb_icon} width={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Level 6 - Translations" />
                        </ListItem>
                    }
                </List>
            </Collapse>


            <ListItem button onClick={settingsMenuClick}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingsMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingsMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem selected={path === 'settings__configs'} button className={classes.nested}
                              onClick={()=>history.push('/settings/configs')}>
                        <ListItemIcon>
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configs" />
                    </ListItem>
                    <ListItem selected={path === 'settings__builds'} button className={classes.nested}
                              onClick={()=>history.push('/settings/builds')}>
                        <ListItemIcon>
                            <DesktopMacIcon />
                        </ListItemIcon>
                        <ListItemText primary="Builds" />
                    </ListItem>
                </List>
            </Collapse>


            <ListItem button onClick={logoutAction}>
                <ListItemIcon>
                    <ExistToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    );
}

export default withRouter(MainList);