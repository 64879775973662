import React, {useState} from 'react';
import {DropzoneDialog} from 'material-ui-dropzone';
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import axios from 'axios';
import {toastWarning} from "../../../utils/utils";
import LoaderBackDropWithPercentage from "../../../components/LoaderWithBackDrop/LoaderBackDropWithPercentage.comp";


function UploadImageDialogue(props) {

    const { openDialogue, dialogueCloseHandler, successCB } = props;

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const FileDialogueHandleSave = (files) => {
        setUploading(true);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        const formData = new FormData();
        formData.append('media', files[0]);

        axios.post(`${process.env.REACT_APP_MYDQ_API}/admin/storage/put`,
            formData, {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 99);
                    setUploadProgress(uploadPercentage);
                }
            }
        ).then(
            result => {
                setUploadProgress(100);
                setTimeout(()=> {
                    setUploading(false);
                    setUploadProgress(0);
                    successCB(result.data.data.media_path)
                }, 200);

            }
        ).catch(
            error => {
                if (error.response.status === 499) throw new Error('Permission denied');

                if (!(error.response && error.response.data && error.response.data.message)) {
                    toastWarning('NETWORK ERROR : Some Thing Went Wrong!');
                } else {
                    toastWarning(error.response.data.message);
                }

                dialogueCloseHandler();
            }
        );



    };

    return (
        <>
            <DropzoneDialog
                open={openDialogue}
                onSave={FileDialogueHandleSave}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
                onClose={dialogueCloseHandler}
            />
            {
                <LoaderBackDropWithPercentage
                    loading={uploading}
                    value={uploadProgress}
                />
            }
        </>
    );
}

export default UploadImageDialogue;