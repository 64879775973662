import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";

import {
    Category108
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: '[AGG] Assess 8',
        field: 'code_agg_a8', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Category108Page() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const cat108_code_data = row.code;
        row.code_agg_a8 = <Link to={`/meta_data/assess8/q=${cat108_code_data}`} className={'meta-data-table-entity-link'} >{cat108_code_data}</Link>;
        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Category 108'}
            modal_title={'Category 108 - Data Preview'}
            sheet_name={'Category 108'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Category108.listData}
            postAPI={Category108.postData}
            deleteAPI={Category108.deleteEntity}
            updateAPI={Category108.updateEntity}
            preRemoveAPI={Category108.preRemoveCheck}
        />
    );
}
