import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";

import {
    Level6
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Full Name', key: 2, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 3, json_key: 'definition', data_key: 'definition'},
    {name: 'App Badge Name', key: 4, json_key: 'app_badge_name', data_key: 'app_badge_name'},
    {name: 'App Badge Description', key: 5, json_key: 'app_badge_desc', data_key: 'app_badge_desc'},
    {name: 'Level-5', key: 6, json_key: 'lvl3_code', data_key: 'lvl3_code_data'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', sort: 'asc', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', sort: 'asc', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'App Badge Name',
        field: 'app_badge_name', sort: 'asc', width: 150
    },
    {
        label: 'App Badge Description',
        field: 'app_badge_desc', sort: 'disabled', width: 150
    },
    {
        label: 'Level 5',
        field: 'lvl3_code', sort: 'asc', width: 150
    },
    {
        label: '[AGG] Assess 8',
        field: 'code_agg_a8', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]

const Level6Page = (props) => {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const lvl3_code_data = row.lvl3_code;
        row.lvl3_code_data = lvl3_code_data;
        row.lvl3_code = <Link to={`/meta_data/level3/q=${lvl3_code_data}`} className={'meta-data-table-entity-link'} >{lvl3_code_data}</Link>;

        const lvl6_code_data = row.code;
        row.code_agg_a8 = <Link to={`/meta_data/assess8/q=${lvl6_code_data}`} className={'meta-data-table-entity-link'} >{lvl6_code_data}</Link>;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level 6 [Badges]'}
            modal_title={'Level 6 - Data Preview'}
            sheet_name={'Level 6'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Level6.listData}
            postAPI={Level6.postData}
            deleteAPI={Level6.deleteEntity}
            updateAPI={Level6.updateEntity}
            preRemoveAPI={Level6.preRemoveCheck}
        />
    );
}

export default Level6Page;
