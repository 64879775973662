import React, {useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, FormControlLabel} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import {USER_ACCESS_LEVEL} from "../../../utils/constants";


const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    btn: {
        marginTop: 16
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const backendToModalPermMap = {
    admin_org_crud_list: 'listing_org',
    admin_org_crud_remove: 'remove_org',
    admin_org_crud_create: 'create_org',
    admin_org_crud_update: 'update_org',
    admin_org_accounts_list: 'listing_account',
    admin_org_accounts_add: 'create_account',
    admin_org_accounts_remove: 'remove_account',
    admin_org_ui_config_area: 'area_ui_config',
    admin_org_ui_config_badge: 'badge_ui_config',
    admin_org_meta_mapping_area: 'area_metadata_mapping',
    admin_org_meta_mapping_badge: 'badge_metadata_mapping',
    admin_org_meta_mapping_package: 'package_metadata_mapping',
    admin_users_list: 'user_list',
    admin_users_update_entity: 'user_update',
    admin_users_add: 'user_add',
    admin_users_remove: 'user_remove',
    admin_meta_data_assess8: 'meta_data_assess8',
    admin_meta_data_level3: 'meta_data_level3',
    admin_meta_data_level6: 'meta_data_level6',
    admin_meta_data_level7: 'meta_data_level7',
    admin_meta_data_cat4: 'meta_data_cat4',
    admin_meta_data_cat11: 'meta_data_cat11',
    admin_meta_data_cat108: 'meta_data_cat108',
    admin_meta_data_packages: 'meta_data_packages',
}

const permsInitStat = {
    full_access: true,

    org_full_access: false,

    listing_org: false,
    remove_org: false,
    create_org: false,
    update_org: false,
    listing_account: false,
    create_account: false,
    remove_account: false,
    area_ui_config: false,
    badge_ui_config: false,
    area_metadata_mapping: false,
    badge_metadata_mapping: false,
    package_metadata_mapping: false,

    user_full_access: false,

    user_list: false,
    user_update: false,
    user_add: false,
    user_remove: false,

    meta_data_full_access: false,

    meta_data_assess8: false,
    meta_data_level3: false,
    meta_data_level6: false,
    meta_data_level7: false,
    meta_data_cat4: false,
    meta_data_cat11: false,
    meta_data_cat108: false,
    meta_data_packages: false
}

function UserPermissionModal(props) {

    const { open, user, updatePermissionsCallback, handleClose } = props

    const [permissionsMap, setPermissionsMap] = useState({
        full_access: true,
        org_full_access: false,
        listing_org: false,
        remove_org: false,
        create_org: false,
        update_org: false,
        listing_account: false,
        create_account: false,
        remove_account: false,
        area_ui_config: false,
        badge_ui_config: false,
        area_metadata_mapping: false,
        badge_metadata_mapping: false,
        package_metadata_mapping: false,
        user_full_access: false,
        user_list: false,
        user_update: false,
        user_add: false,
        user_remove: false,
        meta_data_full_access: false,
        meta_data_assess8: false,
        meta_data_level3: false,
        meta_data_level6: false,
        meta_data_level7: false,
        meta_data_cat4: false,
        meta_data_cat11: false,
        meta_data_cat108: false,
        meta_data_packages: false
    });

    const classes = useStyles();

    useEffect( () => {

        if (user) {

            let permissionsCopy = {...permsInitStat};
            if (user.access_level === USER_ACCESS_LEVEL.FULL_ACCESS_ADMIN_PORTAL) {
                permissionsCopy['full_access'] = true;
                setPermissionsMap({
                    ...permissionsCopy,
                });
            } else {
                const user_permissions = user.permissions;
                permissionsCopy['full_access'] = false;

                let org_full_access = true;
                const admin_org_permissions = [
                    'admin_org_crud_list',
                    'admin_org_crud_remove',
                    'admin_org_crud_create',
                    'admin_org_crud_update',
                    'admin_org_accounts_list',
                    'admin_org_accounts_add',
                    'admin_org_accounts_remove',
                    'admin_org_ui_config_area',
                    'admin_org_ui_config_badge',
                    'admin_org_meta_mapping_area',
                    'admin_org_meta_mapping_badge',
                    'admin_org_meta_mapping_package',
                ];
                for (const perm of admin_org_permissions) {
                    const aPerm = user_permissions[perm];
                    permissionsCopy[backendToModalPermMap[perm]] = aPerm;
                    if (aPerm === false) {
                        org_full_access = false
                    }
                }
                permissionsCopy['org_full_access'] = org_full_access;

                let user_full_access = true;
                const admin_user_permissions = [
                    'admin_users_list',
                    'admin_users_update_entity',
                    'admin_users_add',
                    'admin_users_remove',
                ]
                for (const perm of admin_user_permissions) {
                    const aPerm = user_permissions[perm];
                    permissionsCopy[backendToModalPermMap[perm]] = aPerm;
                    if (aPerm === false) {
                        user_full_access = false
                    }
                }
                permissionsCopy['user_full_access'] = user_full_access;

                let meta_data_full_access = true;
                const admin_meta_data_permissions = [
                    'admin_meta_data_assess8',
                    'admin_meta_data_level3',
                    'admin_meta_data_level6',
                    'admin_meta_data_level7',
                    'admin_meta_data_cat4',
                    'admin_meta_data_cat11',
                    'admin_meta_data_cat108',
                    'admin_meta_data_packages',
                ];
                for (const perm of admin_meta_data_permissions) {
                    const aPerm = user_permissions[perm];
                    permissionsCopy[backendToModalPermMap[perm]] = aPerm;
                    if (aPerm === false) {
                        meta_data_full_access = false
                    }
                }
                permissionsCopy['meta_data_full_access'] = meta_data_full_access;

                setPermissionsMap({
                    ...permissionsCopy,
                })
            }

        }

    }, [user] )

    const updatePermissionMapValue = (key, e) => {
        const permCopy = permissionsMap;
        permCopy[key] = e.target.checked;
        setPermissionsMap({
            ...permCopy
        })
    };

    const renderSwitch = (width, key, label) => {
        return <Grid item xs={width}>
            <FormControlLabel
                control={
                    <Switch
                        checked={permissionsMap[key]} name={key} color="primary"
                        onChange={ (e) => updatePermissionMapValue(key, e) }
                    />}
                label={label}
            />
        </Grid>
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {
                    user && <Fade in={open}>
                        <div className={classes.modalPaper}>

                            <h2 id="transition-modal-title">{'Permissions : ' + user.email}</h2>
                            <br />
                            <div className={classes.form} >
                                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                    {renderSwitch(3, 'full_access', 'Full Access')}
                                </Grid>

                                {
                                    !permissionsMap.full_access && <>
                                        <hr />

                                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                            {renderSwitch(3, 'org_full_access', 'Organizations (Full Access)')}
                                            {
                                                !permissionsMap.org_full_access && <>
                                                    {renderSwitch(3, 'listing_org', 'Listing Org')}
                                                    {renderSwitch(3, 'remove_org', 'Remove org')}
                                                    {renderSwitch(3, 'create_org', 'Create Org')}
                                                    {renderSwitch(3, 'update_org', 'Update Org')}

                                                    {renderSwitch(3, 'listing_account', 'Listing Account')}
                                                    {renderSwitch(3, 'create_account', 'Create Account')}
                                                    {renderSwitch(3, 'remove_account', 'Remove Account')}

                                                    {renderSwitch(3, 'area_ui_config', 'Module UI Config')}
                                                    {renderSwitch(3, 'badge_ui_config', 'Badge UI Config')}

                                                    {renderSwitch(3, 'area_metadata_mapping', 'Area MetaData Mapping')}
                                                    {renderSwitch(3, 'badge_metadata_mapping', 'Badge MetaData Mapping')}
                                                    {renderSwitch(3, 'package_metadata_mapping', 'Package MetaData Mapping')}
                                                </>
                                            }
                                        </Grid>

                                        <hr />

                                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                            {renderSwitch(3, 'user_full_access', 'Users (Full Access)')}
                                            {
                                                !permissionsMap.user_full_access && <>
                                                    {renderSwitch(3, 'user_list', 'Listing User')}
                                                    {renderSwitch(3, 'user_update', 'Update User')}
                                                    {renderSwitch(3, 'user_add', 'Add User')}
                                                    {renderSwitch(3, 'user_remove', 'Remove User')}
                                                </>
                                            }
                                        </Grid>

                                        <hr />

                                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                            {renderSwitch(3, 'meta_data_full_access', 'MetaData (Full Access)')}
                                            {
                                                !permissionsMap.meta_data_full_access && <>
                                                    {renderSwitch(3, 'meta_data_assess8', 'Assess8 MetaData')}
                                                    {renderSwitch(3, 'meta_data_level3', 'Level3 MetaData')}
                                                    {renderSwitch(3, 'meta_data_level6', 'Level6 MetaData')}
                                                    {renderSwitch(3, 'meta_data_level7', 'Level7 MetaData')}
                                                    {renderSwitch(3, 'meta_data_cat4', 'Category4 MetaData')}
                                                    {renderSwitch(3, 'meta_data_cat11', 'Category11 MetaData')}
                                                    {renderSwitch(3, 'meta_data_cat108', 'Category108 MetaData')}
                                                    {renderSwitch(3, 'meta_data_packages', 'Packages MetaData')}
                                                </>
                                            }
                                        </Grid>
                                    </>
                                }

                                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={ () => updatePermissionsCallback(permissionsMap) }
                                        >
                                            Save
                                        </Button>

                                    </Grid>
                                </Grid>

                            </div>

                        </div>
                    </Fade>
                }
            </Modal>
        </>
    );
}

export default UserPermissionModal;