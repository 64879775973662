
export const CURRENT_VERSION = '1.12';
export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_admin_auth_token',
    auth_user: 'dq_admin_auth_user'
}

export const USER_TYPE = {
    REGULAR: 10,
    SUPER_USER: 1,
    ADMIN_PORTAL: 2,
    ORG_PORTAL: 3
};

export const ORG_TYPE = {
    1: 'Default',
    2: 'Company',
    3: 'School'
}

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const USER_TYPE_VERBOSE = {
    10: 'REGULAR',
    1: 'SUPER_USER',
    2: 'ADMIN_PORTAL',
    3: 'ORG_PORTAL'
};

export const USER_ACCESS_LEVEL_VERBOSE = {
    0: 'N/A',
    1: 'No Access',
    2: 'Partial Access (Admin)',
    3: 'Full Access (Admin)',
    4: 'Full Access (Analytics)',
    5: 'Partial Access (Analytics)',
};

export const URL_PERMS_MAP = {
    'meta_data/assess8': 'admin_meta_data_assess8',
    'meta_data/level3': 'admin_meta_data_level3',
    'meta_data/level6': 'admin_meta_data_level6',
    'meta_data/level7': 'admin_meta_data_level7',
    'meta_data/cat4': 'admin_meta_data_cat4',
    'meta_data/cat11': 'admin_meta_data_cat11',
    'meta_data/cat108': 'admin_meta_data_cat108',
    'meta_data/packages': 'admin_meta_data_packages',
    'org/list': 'admin_org_crud_list',
    'org/accounts': 'admin_org_accounts_list',
    'org/area_ui_conf': 'admin_org_ui_config_area',
    'org/badge_ui_conf': 'admin_org_ui_config_badge',
    'org/all_areas': 'admin_org_meta_mapping_area',
    'org/all_badges': 'admin_org_meta_mapping_badge',
    'org/all_packages': 'admin_org_meta_mapping_package',
    'users/list': 'admin_users_list',
}

export const ACL_ENABLE_ENDPOINTS = [
    'meta_data/assess8',
    'meta_data/level3',
    'meta_data/level6',
    'meta_data/level7',
    'meta_data/cat4',
    'meta_data/cat11',
    'meta_data/cat108',
    'meta_data/packages',
    'org/list',
    'org/accounts',
    'org/area_ui_conf',
    'org/badge_ui_conf',
    'org/all_areas',
    'org/all_badges',
    'org/all_packages',
    'users/list',
]

export const SECTION_ACL_KEYS = {
    META_DATA_ASSESS8: 'admin_meta_data_assess8',
    META_DATA_LEVEL3: 'admin_meta_data_level3',
    META_DATA_LEVEL6: 'admin_meta_data_level6',
    META_DATA_LEVEL7: 'admin_meta_data_level7',
    META_DATA_CAT4: 'admin_meta_data_cat4',
    META_DATA_CAT11: 'admin_meta_data_cat11',
    META_DATA_CAT108: 'admin_meta_data_cat108',
    META_DATA_PACKAGES: 'admin_meta_data_packages',
    ORG_CRUD_LIST: 'admin_org_crud_list',
    ORG_ACCOUNTS_LIST: 'admin_org_accounts_list',
    ORG_UI_CONFIG_AREA: 'admin_org_ui_config_area',
    ORG_UI_CONFIG_BADGE: 'admin_org_ui_config_badge',
    ORG_META_MAPPING_AREA: 'admin_org_meta_mapping_area',
    ORG_META_MAPPING_BADGE: 'admin_org_meta_mapping_badge',
    ORG_META_MAPPING_PACKAGE: 'admin_org_meta_mapping_package',
    USERS_LIST: 'admin_users_list',
}

export const NESTED_ACL_KEYS = {
    ORG_CRUD_REMOVE: 'admin_org_crud_remove',
    ORG_CRUD_CREATE: 'admin_org_crud_create',
    ORG_CRUD_UPDATE: 'admin_org_crud_update',
    ORG_ACCOUNTS_ADD: 'admin_org_accounts_add',
    ORG_ACCOUNTS_REMOVE: 'admin_org_accounts_remove',
    USERS_UPDATE_ENTITY: 'admin_users_update_entity',
    USERS_ADD: 'admin_users_add',
    USERS_REMOVE: 'admin_users_remove',
}

export const LICENSES_ORDER_STATUS_VERBOSE = {
    0: 'Not Set',
    1: 'Pending',
    2: 'Approved',
    3: 'Rejected',
    4: 'Withdrawn'
}

export const LICENSES_ORDER_STATUS = {
    NOT_SET: 0,
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    WITHDRAWN: 4,
};