import React, {useState, useEffect} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import Backdrop from "@material-ui/core/Backdrop";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    tr_act: {
        background: '#e7fcde',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function BadgeSelectionModal(props) {

    const { open, handleClose, org_code, badgesMeta, badgeCodesMap, updatePresence, saveChanges } = props

    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className={classes.modalPaper}>

                        <h3 id="transition-modal-title">{'Selecting Badges For => ' + org_code}</h3>
                        <br />
                        <Button
                            style={{
                                width: '25%',
                                marginBottom: '16px'
                            }}
                            variant="contained"  color="primary"
                            onClick={saveChanges}>Save Changes</Button>

                        {
                            badgeCodesMap && <MDBTable>
                                <MDBTableHead color="primary-color" textWhite>
                                    <tr>
                                        <th>Id</th>
                                        <th>Code</th>
                                        <th>Full Name</th>
                                        <th>Area Code</th>
                                        <th>Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {
                                        badgesMeta.map( (item, index) => (
                                            <tr className={badgeCodesMap[item.code] ? classes.tr_act : classes.tr_d_act}>
                                                <td>{item.id}</td>
                                                <td>{item.code}</td>
                                                <td>{item.app_badge_name}</td>
                                                <td>{item.lvl3_code}</td>
                                                <td>
                                                    {
                                                        badgeCodesMap[item.code] ?
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, false)}
                                                                className={classes.d_act_btn}>Remove From=> {org_code}</Button>
                                                            :
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, true)}
                                                                className={classes.act_btn}>Add to=> {org_code}</Button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        }

                    </div>

                </Fade>
            </Modal>
        </>
    );

}

export default BadgeSelectionModal