import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {getPresenceMap, getMapFromObjectListByKey, toastWarning} from "../../../utils/utils";
import {OrgCRUD_Services, AllPackagesServices} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";

import PackageSelectionModal from '../components/PackagesSelectionModal.comp';
import {Packages} from "../../../services/metadata.service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function All_Packages(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    // const [packCodes, setPackCodes] = useState([]);
    const [packCodesMap, setPackCodesMap] = useState({});

    const [packagesMeta, setPackagesMeta] = useState([]);
    const [packagesMetaMap, setPackagesMetaMap] = useState({});

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
        loadPackagesMeta().then(d => console.log('pack-meta data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadPackCodes().then(d => console.log('pack-codes data loaded!', d));
        }
    }, [activeORG]);


    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPackagesMeta = async () => {
        setLoading(true);

        try {
            const result = await Packages.listData();
            if (result.data && result.data.metaData) {
                const all_metadata = result.data.metaData
                setPackagesMeta(all_metadata);
                setPackagesMetaMap(getMapFromObjectListByKey(all_metadata, 'code'))
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPackCodes = async () => {
        setLoading(true);

        if (activeORG !== '') {
            try {
                const result = await AllPackagesServices.listPacks(activeORG);
                if (result.data && result.data.pack_codes) {
                    const codes = result.data.pack_codes;
                    // setPackCodes(codes)
                    setPackCodesMap(getPresenceMap(codes))
                }
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const updatePresence = (code, added) => {
        const presence = {
            ...packCodesMap,
            [code]: added
        }
        setPackCodesMap(presence);
    }

    const openSelectionClick = () => {
        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            setOpenSelectionModal(true);
        }
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const saveChanges = async () => {
        setLoading(true);

        const presentCodes = packCodesMap;
        let pack_codes = [];
        for (let code of Object.keys(presentCodes)) {
            if (presentCodes[code]) {
                pack_codes.push(code);
            }
        }

        try {
            await AllPackagesServices.mapPacks(pack_codes, activeORG);
        } catch (e) {
            // error already toasted
        }

        setOpenSelectionModal(false);
        setLoading(false);
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>

                <Button
                    variant="contained"  color="primary"
                    onClick={openSelectionClick}
                >Select Packages</Button>
            </div>

            <div className={classes.paper}>
                <h3>All Packages</h3>
            </div>

            {
                packCodesMap && <MDBTable>
                    <MDBTableHead color="primary-color" textWhite>
                        <tr>
                            <th>Sr.#</th>
                            <th>Code</th>
                            <th>Full Name</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            Object.keys(packCodesMap).map( (code, index) => (
                                packCodesMap[code] && packagesMetaMap[code] ?
                                <tr className={true ? classes.tr_act : classes.tr_d_act}>
                                    <td>{index+1}</td>
                                    <td>{packagesMetaMap[code].code}</td>
                                    <td>{packagesMetaMap[code].full_name}</td>
                                </tr> : undefined
                            ))
                        }
                    </MDBTableBody>
                </MDBTable>
            }

            {
                <PackageSelectionModal
                    open={openSelectionModal}
                    org_code={activeORG}
                    packagesMeta={packagesMeta}
                    packCodesMap={packCodesMap}
                    updatePresence={updatePresence}
                    saveChanges={saveChanges}
                    handleClose={ ()=> {
                        setOpenSelectionModal(false);
                    } }
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default All_Packages;