import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {getMapFromObjectListByKey, getPresenceMap, toastWarning} from "../../../utils/utils";
import {AllAreaServices, AllBadgesServices, OrgCRUD_Services} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";

import {Level3, Level6} from "../../../services/metadata.service";

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function All_Areas(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [areaCodesMap, setAreaCodesMap] = useState({});
    const [allAreasMap, setAllAreasMap] = useState({});

    const [areaMetaMap, setAreaMetaMap] = useState({});

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
        loadAreaMeta().then(d => console.log('area-meta data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadAreaCodes().then(d => console.log('area-codes data loaded!', d));
        }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const loadAreaMeta = async () => {
        setLoading(true);

        try {
            const result = await Level3.listData();
            if (result.data && result.data.metaData) {
                const all_metadata = result.data.metaData
                setAreaMetaMap(getMapFromObjectListByKey(all_metadata, 'code'))
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadAreaCodes = async () => {
        setLoading(true);

        if (activeORG !== '') {
            try {
                const result = await AllAreaServices.listArea(activeORG);
                if (result.data && result.data.area_codes && result.data.all_areas) {
                    const codes = result.data.area_codes;
                    setAreaCodesMap(getPresenceMap(codes))
                    setAllAreasMap(getMapFromObjectListByKey(result.data.all_areas, 'area_code'));
                }
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const syncWithBadgesClick = async () => {
        setLoading(true);

        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            try {
                await AllAreaServices.syncBadgeAreas(activeORG);
                await loadAreaCodes()
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>

                <Button
                    variant="contained"  color="primary"
                    onClick={syncWithBadgesClick}
                >Sync Badge Areas</Button>
            </div>

            <div className={classes.paper}>
                <h3>All Modules(Areas)</h3>
            </div>

            {
                areaCodesMap && <MDBTable>
                    <MDBTableHead color="primary-color" textWhite>
                        <tr>
                            <th>Sr.#</th>
                            <th>Code</th>
                            <th>Full Name</th>
                            <th>License Cost</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            Object.keys(areaCodesMap).map( (code, index) => (
                                areaCodesMap[code] && areaMetaMap[code] && allAreasMap[code]?
                                    <tr className={true ? classes.tr_act : classes.tr_d_act}>
                                        <td>{index+1}</td>
                                        <td>{areaMetaMap[code].code}</td>
                                        <td>{areaMetaMap[code].full_name}</td>
                                        <td>{allAreasMap[code].license_cost_per_user}</td>
                                    </tr> : undefined
                            ))
                        }
                    </MDBTableBody>
                </MDBTable>
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default All_Areas;