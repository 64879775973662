import { invokeApi } from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

export const loginUser = (email, password, remember_me) => {
    let requestObj = {
        path   : '/admin/auth/login',
        method : 'POST'
    };
    const postData = {
        email,
        password,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const registerUser = (email, password, remember_me) => {
    let requestObj = {
        path   : '/admin/auth/register',
        method : 'POST'
    };
    const postData = {
        email,
        password,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/logout',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        all_devices: true
    };

    return invokeApi(requestObj);
};

export const initResetPassword = (email) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/init/reset/password',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        email
    };

    return invokeApi(requestObj);
}

export const resetPassword = (reset_code, password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/password/reset',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        reset_code,
        password
    };

    return invokeApi(requestObj);
}

export const changePassword = (old_password, new_password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/password/change',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        old_password,
        new_password
    };

    return invokeApi(requestObj);
};

export const resendEmailConfirmation = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/email/resend/confirm_token',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
};

export const confirmEmail = (confirmation_token) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/auth/email/confirm',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        confirmation_token
    };

    return invokeApi(requestObj);
}