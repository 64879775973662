import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {toastWarning} from "../../../utils/utils";
import {OrgCRUD_Services, BadgeUIConfServices} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";
import UploadImageDialogue from "../components/uploadImageDialogue.comp";

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function Badges_UI_Conf(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [pageData, setPageData] = useState([]);
    const [updateIconOfItem, setUpdateIconOfItem] = useState(-1);

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadPageData().then(d => console.log('table data loaded!', d));
        }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPageData = async () => {
        if (activeORG !== '') {
            setLoading(true);

            try {
                const result = await BadgeUIConfServices.list(activeORG)
                if (result.data && result.data.badges) {
                    setPageData(result.data.badges);
                }
            } catch (e) {
                // error already toasted
            }

            setLoading(false);
        }
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const syncWithMetaData = async () => {
        setLoading(true);

        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            try {
                await BadgeUIConfServices.sync(activeORG);
                await loadPageData();
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const copyConfFromOrg = () => {
        toastWarning('Not Implemented !!');
    }

    const saveUpdateIcon = async (iconURL) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[updateIconOfItem].icon_url = iconURL;
        try {
            await BadgeUIConfServices.update(data_copy[updateIconOfItem]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setUpdateIconOfItem(-1);
        setLoading(false);
    }

    const activateItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = true;
        try {
            await BadgeUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const disableItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = false;
        try {
            await BadgeUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>

                <Button
                    style={{
                        marginRight: '8px'
                    }}
                    variant="contained"  color="primary"
                    onClick={copyConfFromOrg}
                >Copy Configs of Org?</Button>

                <Button
                    variant="contained"  color="primary"
                    onClick={syncWithMetaData}
                >Sync with MetaData</Button>
            </div>

            <div className={classes.paper}>
                <h3>Badges (Level-6) [UI Configuration]</h3>
            </div>

            <MDBTable>
                <MDBTableHead color="primary-color" textWhite>
                    <tr>
                        <th>Sr.#</th>
                        <th>Code</th>
                        <th>Area Code</th>
                        <th>Duration</th>
                        <th>Icon</th>
                        <th>Actions</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        pageData.map( (item, index) => (
                            <tr className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                <td>{index+1}</td>
                                <td>{item.badge_code}</td>
                                <td>{item.area_code}</td>
                                <td>{item.duration}</td>
                                <td>
                                    <img className={classes.imageDiv} src={renderURL(item.icon_url)}
                                         onClick={()=>{setUpdateIconOfItem(index)}}
                                    />
                                </td>
                                {
                                    item.is_active ?
                                        <td><Button onClick={()=>disableItem(index)}
                                                    className={classes.d_act_btn}>Disable</Button></td> :
                                        <td><Button onClick={()=>activateItem(index)}
                                                    className={classes.act_btn}>Activate</Button></td>
                                }
                            </tr>
                        ))
                    }
                </MDBTableBody>
            </MDBTable>

            <LoaderWithBackDrop loading={loading} />

            <UploadImageDialogue
                openDialogue={updateIconOfItem !== -1}
                dialogueCloseHandler={()=>{setUpdateIconOfItem(-1)}}
                successCB={saveUpdateIcon}
            />

        </Container>
    );
}

export default Badges_UI_Conf;