import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import OrganizationModalForm from '../components/OrganizationModalForm.comp';

import {OrgCRUD_Services} from "../../../services/organization.service";
import {toastWarning} from "../../../utils/utils";
import {NESTED_ACL_KEYS, USER_ACCESS_LEVEL} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function List_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [objectData, setObjectData] = useState(undefined);
    const [openOrgForm, setOpenOrgForm] = useState(false);
    const [orgFormTitle, setOrgFormTitle] = useState('');

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));

        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                perms[NESTED_ACL_KEYS.ORG_CRUD_CREATE] ? setShowCreateBtn(true) : setShowCreateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_CRUD_UPDATE] ? setShowUpdateBtn(true) : setShowUpdateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_CRUD_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                setShowCreateBtn(false)
                setShowUpdateBtn(false)
                setShowDeleteBtn(false)
            } else {
                setShowCreateBtn(true)
                setShowUpdateBtn(true)
                setShowDeleteBtn(true)
            }
        }


    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                console.log('all orgs --> ', result.data.ORGs);
                setPageData(result.data.ORGs);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const openCreateModal = () => {
        setObjectData({
            org_code: '',
            org_type: 1,
            contact_email: '',
            org_name: '',
            org_address: '',
            org_city: '',
            org_country: '',
            callback_url: '',
            callback_key: ''
        })
        setOpenOrgForm(true);
        setOrgFormTitle('Create New Organization');
    }

    const openEditModal = (record) => {
        setObjectData({
            id: record.id,
            org_code: record.org_code,
            org_type: record.type,
            contact_email: record.contact_email,
            org_name: record.org_name,
            org_address: record.org_address,
            org_city: record.org_city,
            org_country: record.org_country,
            callback_url: record.callback_url,
            callback_key: record.callback_key
        })
        setOpenOrgForm(true);
        setOrgFormTitle('Update Organization');
    }

    const invokeUpdateOrCreateService = async (data) => {
        console.log('invokeUpdateOrCreateService ..... with data --> ', data);

        setLoading(true);

        let close_operation = true;
        if (objectData.id){
            // invoke update API
            await OrgCRUD_Services.updateEntity(
                {
                    id: objectData.id,
                    ...data
                }
            );
            loadPageData().then(d => console.log('table data loaded!', d));
        } else {
            // invoke create API
            let valid_code = true;
            try {
                // pre-create check
                const api_1_res = await OrgCRUD_Services.preCreateCheck(data.org_code);
                if (api_1_res.data.deny) {
                    toastWarning(api_1_res.data.deny_message);
                    valid_code = false;
                    close_operation = false;
                }
            } catch (e) {
                close_operation = false;
                // error already toasted
            }

            if (valid_code) {
                try {
                    const new_entity = await OrgCRUD_Services.createEntity(data);
                    console.log('success fully created entity ---> ', new_entity);
                    loadPageData().then(d => console.log('table data loaded!', d));
                } catch (e) {
                    close_operation = false;
                    // error already toasted
                }
            }
        }

        if (close_operation) {
            setOpenOrgForm(false);
            setObjectData(undefined);
        }
        setLoading(false);
    }

    const invokeDeleteService = async (id) => {
        try {
            await OrgCRUD_Services.deleteEntity(id);
            loadPageData().then(d => console.log('table data loaded!', d));
        } catch (e) {
            // error already toasted
        }
    }


    return (
        <Container component="main">

            <div className={classes.headerInputGroup}>
                {
                    showCreateBtn && <Button
                        variant="contained"  color="primary"
                        onClick={openCreateModal}
                    >Create</Button>
                }
            </div>

            <div className={classes.paper}>
                <h3>All Organizations</h3>
            </div>

            <MDBTable>
                <MDBTableHead color="primary-color" textWhite>
                    <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>App Key</th>
                        <th>Secret Key</th>
                        <th>Access Code</th>
                        <th>Actions</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        pageData.map( (item, index) => (
                            <tr className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                <td>{item.org_code}</td>
                                <td>{item.org_name}</td>
                                <td>{item.app_key}</td>
                                <td>{item.secret_key}</td>
                                <td>{item.access_code}</td>
                                <td>
                                    {
                                        showDeleteBtn && <Button onClick={()=>invokeDeleteService(item.id)}
                                                                 className={classes.d_act_btn}>Delete</Button>
                                    }
                                    {
                                        showUpdateBtn && <Button onClick={()=>openEditModal(item)}
                                                                 className={classes.act_btn}>Edit</Button>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </MDBTableBody>
            </MDBTable>

            {
                objectData && <OrganizationModalForm
                    open={openOrgForm}
                    title={orgFormTitle}
                    handleClose={ ()=> {
                        setOpenOrgForm(false);
                        setObjectData(undefined);
                    } }
                    submitCallback={invokeUpdateOrCreateService}
                    dataObject={objectData}
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default List_ORG;