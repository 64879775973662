import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {getMapFromObjectListByKey, getPresenceMap, toastWarning} from "../../../utils/utils";
import {AllBadgesServices, OrgCRUD_Services} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";

import BadgeSelectionModal from '../components/BadgeSelectionModal.comp';
import {Level6} from "../../../services/metadata.service";

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function All_Badges(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    const [badgeCodesMap, setBadgeCodesMap] = useState({});
    const [allBadgesMap, setAllBadgesMap] = useState({});

    const [badgesMeta, setBadgesMeta] = useState([]);
    const [badgesMetaMap, setBadgesMetaMap] = useState({});

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
        loadBadgesMeta().then(d => console.log('pack-meta data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadBadgeCodes().then(d => console.log('pack-codes data loaded!', d));
        }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadBadgesMeta = async () => {
        setLoading(true);

        try {
            const result = await Level6.listData();
            if (result.data && result.data.metaData) {
                const all_metadata = result.data.metaData
                setBadgesMeta(all_metadata);
                setBadgesMetaMap(getMapFromObjectListByKey(all_metadata, 'code'))
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadBadgeCodes = async () => {
        setLoading(true);

        if (activeORG !== '') {
            try {
                const result = await AllBadgesServices.listBadges(activeORG);
                if (result.data && result.data.badge_codes && result.data.all_badges) {
                    const codes = result.data.badge_codes;
                    // setPackCodes(codes)
                    setBadgeCodesMap(getPresenceMap(codes))
                    setAllBadgesMap(getMapFromObjectListByKey(result.data.all_badges, 'badge_code'))
                }
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const openSelectionClick = () => {
        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            setOpenSelectionModal(true);
        }
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const updatePresence = (code, added) => {
        const presence = {
            ...badgeCodesMap,
            [code]: added
        }
        setBadgeCodesMap(presence);
    }

    const saveChanges = async () => {
        setLoading(true);

        const presentCodes = badgeCodesMap;
        let badge_codes = [];
        for (let code of Object.keys(presentCodes)) {
            if (presentCodes[code]) {
                badge_codes.push(code);
            }
        }

        try {
            await AllBadgesServices.mapBadges(badge_codes, activeORG);
            setTimeout(()=>{
                loadBadgeCodes().then(d => console.log('pack-codes data loaded!', d));
            }, 200)
        } catch (e) {
            // error already toasted
        }

        setOpenSelectionModal(false);
        setLoading(false);
    }

    const syncPackageBadgesClick = async () => {
        setLoading(true);

        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            try {
                await AllBadgesServices.syncPackageBadges(activeORG);
                await loadBadgeCodes();
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>

                <Button
                    style={{
                        marginRight: '8px'
                    }}
                    variant="contained"  color="primary"
                    onClick={syncPackageBadgesClick}
                >Sync Package Badges</Button>
                <Button
                    variant="contained"  color="primary"
                    onClick={openSelectionClick}
                >Select Badges</Button>
            </div>

            <div className={classes.paper}>
                <h3>All Badges</h3>
            </div>

            {
                badgeCodesMap && <MDBTable>
                    <MDBTableHead color="primary-color" textWhite>
                        <tr>
                            <th>Sr.#</th>
                            <th>Code</th>
                            <th>Full Name</th>
                            <th>Area Code</th>
                            <th>License Cost</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            Object.keys(badgeCodesMap).map( (code, index) => (
                                badgeCodesMap[code] && badgesMetaMap[code] && allBadgesMap[code]?
                                    <tr className={true ? classes.tr_act : classes.tr_d_act}>
                                        <td>{index+1}</td>
                                        <td>{badgesMetaMap[code].code}</td>
                                        <td>{badgesMetaMap[code].app_badge_name}</td>
                                        <td>{badgesMetaMap[code].lvl3_code}</td>
                                        <td>{allBadgesMap[code].license_cost_per_user}</td>
                                    </tr> : undefined
                            ))
                        }
                    </MDBTableBody>
                </MDBTable>
            }

            {
                <BadgeSelectionModal
                    open={openSelectionModal}
                    org_code={activeORG}
                    badgesMeta={badgesMeta}
                    badgeCodesMap={badgeCodesMap}
                    updatePresence={updatePresence}
                    saveChanges={saveChanges}
                    handleClose={ ()=> {
                        setOpenSelectionModal(false);
                    } }
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default All_Badges;