import {store} from "react-notifications-component";

export const toastWarning = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true
        }
    });
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}