import React from 'react';



const AppInfo = () => {


    return (
        <p
            style={{
                padding: '15vw',
                textAlign: "center"
            }}
        >1.0.1</p>
    );
};


export default AppInfo;
