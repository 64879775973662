import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {toastWarning} from "../../../utils/utils";
import {OrgCRUD_Services, BadgeUIConfServices, OrgConfigServices} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";
import UploadImageDialogue from "../components/uploadImageDialogue.comp";

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function Config_ORG(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG_Obj, setActiveORG_Obj] = useState(undefined);
    const [activeORG_Code, setActiveORG_Code] = useState("");
    const [supportedLocales, setSupportedLocales] = useState("");

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                const _all_orgs = result.data.ORGs
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG_Obj(_all_orgs[0]);
                    setActiveORG_Code(_all_orgs[0].org_code)
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG_Code(e.target.value);
        const target_orgs = allORGs.filter( item => item.org_code === e.target.value )
        if (target_orgs.length > 0) {
            setActiveORG_Obj(target_orgs[0])

            console.log('target_orgs[0] --> ', target_orgs[0]);
        }
    }

    const Enable_UiConfigSection = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_config_enable = true
            }
        }
        try {
            await OrgConfigServices.enable_ui_config(activeORG_Code);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_config_enable = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disables_UiConfigSection = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_config_enable = false
            }
        }
        try {
            await OrgConfigServices.disable_ui_config(activeORG_Code);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_config_enable = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG_Code}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item.org_code}>{item.org_code}</option>)
                }
            </NativeSelect>


            <div className={classes.paper}>
                <h3>Organization Configuration</h3>
            </div>

            <MDBTable>
                <MDBTableHead color="primary-color" textWhite>
                    <tr>
                        <th>Config Name</th>
                        <th>Current Value</th>
                        <th>Actions</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        activeORG_Obj && <tr className={activeORG_Obj.ui_config_enable ? classes.tr_act : classes.tr_d_act}>
                            <td>{'UI Config Section'}</td>
                            <td>{activeORG_Obj.ui_config_enable ? 'Visible' : 'Hidden'}</td>
                            {
                                activeORG_Obj.ui_config_enable ?
                                    <td><Button onClick={()=>Disables_UiConfigSection()}
                                                className={classes.d_act_btn}>Disable</Button></td> :
                                    <td><Button onClick={()=>Enable_UiConfigSection()}
                                                className={classes.act_btn}>Activate</Button></td>
                            }
                        </tr>
                    }
                    {
                        activeORG_Obj && <tr className={classes.tr_act}>
                            <td>{'Supported Locales'}</td>
                            <td>{activeORG_Obj.supported_locales.full_access ? 'All' : activeORG_Obj.supported_locales.allowed.join(', ')}</td>
                            <td>{'N/A'}</td>
                            {/*{*/}
                            {/*    activeORG_Obj.ui_config_enable ?*/}
                            {/*        <td><Button onClick={()=>Disables_UiConfigSection()}*/}
                            {/*                    className={classes.d_act_btn}>Disable</Button></td> :*/}
                            {/*        <td><Button onClick={()=>Enable_UiConfigSection()}*/}
                            {/*                    className={classes.act_btn}>Activate</Button></td>*/}
                            {/*}*/}
                        </tr>
                    }
                </MDBTableBody>
            </MDBTable>

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default Config_ORG;