import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {toastWarning} from "../../../utils/utils";
import {OrgOrderServices, OrgCRUD_Services} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";
import AddOrderModal from '../components/AddOrderModal.comp';
import {useAuth} from "../../../context/auth";
import {LICENSES_ORDER_STATUS, LICENSES_ORDER_STATUS_VERBOSE} from "../../../utils/constants";


const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function Orders_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [ordersData, setOrdersData] = useState([]);

    const [showAddOrderModal, setShowAddOrderModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(true);

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadOrdersData().then(d => console.log('orders data loaded!', d));
        }

        // // // ACL layer code for specific page have to be added, following code block is copied from accounts page.
        // if (authUser) {
        //     if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
        //         const perms = authUser.permissions;
        //         perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
        //         perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
        //     } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
        //         setShowCreateBtn(false)
        //         setShowDeleteBtn(false)
        //     } else {
        //         setShowCreateBtn(true)
        //         setShowDeleteBtn(true)
        //     }
        // }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const loadOrdersData = async () => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await OrgOrderServices.list_orders(activeORG);
                if (result.data && result.data.orders) {
                    setOrdersData(result.data.orders);
                }
            } catch (e) {
                // error already toasted
            }
            setLoading(false);
        }
    }

    const approveOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.approve_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.APPROVED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const rejectOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.reject_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.REJECTED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const createOrderAnalyticsPortal = () => {
        if (activeORG) {
            setShowAddOrderModal(true);
        } else {
            toastWarning('in order to create an order, please select an organization first!')
        }
    }

    const createOrderAnalyticsPortalService = async (licenses_count, order_details) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await OrgOrderServices.create_order(activeORG, order_details, licenses_count);
                if (result.data && result.data.order) {
                    let orders = ordersData;
                    orders.push(result.data.order);
                    setOrdersData([...orders]);
                }
            } catch (e) {
                // error already toasted
                console.log('OrgOrderServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('in order to create an order, please select an organization first!')
        }

        setShowAddOrderModal(false);
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>
                {
                    showCreateBtn && <Button
                        variant="contained"  color="primary"
                        onClick={createOrderAnalyticsPortal}
                    >Create Order</Button>
                }
            </div>

            <div className={classes.paper}>
                <h3>All Orders</h3>
            </div>

            {
                <MDBTable>
                    <MDBTableHead color="primary-color" textWhite>
                        <tr>
                            <th>Sr.#</th>
                            <th>Order ID</th>
                            <th>License Count</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            ordersData.map( (order, index) => (
                                <tr className={true ? classes.tr_act : classes.tr_d_act}>
                                    <td>{index+1}</td>
                                    <td>{order.id}</td>
                                    <td>{order.license_count}</td>
                                    <td>{LICENSES_ORDER_STATUS_VERBOSE[order.status]}</td>
                                    {
                                        order.status === LICENSES_ORDER_STATUS.PENDING ? <td>
                                            <Button onClick={()=>rejectOrder(order.id)}
                                                className={classes.d_act_btn}>Reject</Button>
                                            <Button onClick={()=>approveOrder(order.id)}
                                                className={classes.act_btn}>Approve</Button>
                                        </td> : <td>{"No Action!"}</td>
                                    }

                                </tr>
                            ) )

                        }
                    </MDBTableBody>
                </MDBTable>
            }

            <AddOrderModal
                open={showAddOrderModal}
                createOrderCallback={createOrderAnalyticsPortalService}
                handleClose={ () => setShowAddOrderModal(false) }
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
};

export default Orders_ORG;