import { invokeApi } from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";


export const Assess8 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Level3 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Level6 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Level7 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level7/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level7/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Category4 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat4/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat4/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Category11 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat11/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat11/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Category108 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat108/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat108/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Packages = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/packages/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        console.log('packages update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/packages/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Assess8_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Level3_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}

export const Level6_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj);
    }
}