import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {toastWarning} from "../../../utils/utils";
import {AccountServices, OrgCRUD_Services} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";
import AddAccountModal from '../components/AddAccountModal.comp';
import {USER_ACCESS_LEVEL, USER_ACCESS_LEVEL_VERBOSE} from "../../../utils/constants";
import {NESTED_ACL_KEYS} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";


const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));

function Accounts_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [accountsData, setAccountsData] = useState([]);

    const [showAddAccountModal, setShowAddAccountModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadAccountsData().then(d => console.log('accounts data loaded!', d));
        }

        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                setShowCreateBtn(false)
                setShowDeleteBtn(false)
            } else {
                setShowCreateBtn(true)
                setShowDeleteBtn(true)
            }
        }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const loadAccountsData = async () => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.list_accounts(activeORG);
                if (result.data && result.data.accounts) {
                    setAccountsData(result.data.accounts);
                }
            } catch (e) {
                // error already toasted
            }
            setLoading(false);
        }
    }

    const createAccountAnalyticsPortal = () => {
        if (activeORG) {
            setShowAddAccountModal(true);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }
    }

    const createAccountAnalyticsPortalService = async (email) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.add_account(activeORG, email);
                if (result.data && result.data.new_account) {
                    let accounts = accountsData;
                    accounts.push(result.data.new_account);
                    setAccountsData([...accounts]);
                }
            } catch (e) {
                // error already toasted
                console.log('AccountServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }

        setShowAddAccountModal(false);
    }

    const invokeDeleteAccountService = async (member_id) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.remove_account(activeORG, member_id);
                if (result.data && result.data.deleted) {
                    let accounts = accountsData.filter(account => account.member_id !== member_id);
                    setAccountsData([...accounts]);
                }
            } catch (e) {
                // error already toasted
                console.log('AccountServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('please select an valid organization first!')
        }
    }

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>

            <div className={classes.headerInputGroup}>
                {
                    showCreateBtn && <Button
                        variant="contained"  color="primary"
                        onClick={createAccountAnalyticsPortal}
                    >Create Account</Button>
                }
            </div>

            <div className={classes.paper}>
                <h3>All Accounts</h3>
            </div>

            {
                <MDBTable>
                    <MDBTableHead color="primary-color" textWhite>
                        <tr>
                            <th>Sr.#</th>
                            <th>Email</th>
                            <th>Member-ID</th>
                            <th>Access-Level</th>
                            <th>Actions</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            accountsData.map( (account, index) => (
                                <tr className={true ? classes.tr_act : classes.tr_d_act}>
                                    <td>{index+1}</td>
                                    <td>{account.email}</td>
                                    <td>{account.member_id}</td>
                                    <td>{USER_ACCESS_LEVEL_VERBOSE[account.access_level]}</td>
                                    <td>
                                        {
                                            showDeleteBtn && <Button onClick={()=>invokeDeleteAccountService(account.member_id)}
                                                                     className={classes.d_act_btn}>Delete</Button>
                                        }
                                    </td>
                                </tr>
                            ) )

                        }
                    </MDBTableBody>
                </MDBTable>
            }

            <AddAccountModal
                open={showAddAccountModal}
                createAccountCallback={createAccountAnalyticsPortalService}
                handleClose={ () => setShowAddAccountModal(false) }
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
};

export default Accounts_ORG;