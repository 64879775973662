import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const OrgCRUD_Services = {
    preCreateCheck: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/pre_create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { org_code };
        return invokeApi(requestObj);
    },
    createEntity: (entityObject) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = entityObject;
        return invokeApi(requestObj);
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (entityObject) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/update/' + entityObject.id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = entityObject;
        return invokeApi(requestObj);
    }
}

export const AllPackagesServices = {
    listPacks: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/package/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    mapPacks: (pack_codes, org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/package/map',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            pack_codes,
            org_code
        };
        return invokeApi(requestObj);
    }
}

export const AllBadgesServices = {
    listBadges: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    mapBadges: (badge_codes, org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/map',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            badge_codes,
            org_code
        };
        return invokeApi(requestObj);
    },
    syncPackageBadges: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/sync',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    }
}

export const AllAreaServices = {
    listArea: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/area/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    syncBadgeAreas: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/area/sync',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    }
}

export const AreaUIConfServices = {
    sync: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/sync_meta',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj);
    },
    copy: () => {

    }
}

export const BadgeUIConfServices = {
    sync: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/sync_meta',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj);
    },
    copy: () => {

    }
}

export const AccountServices = {

    list_accounts: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },

    add_account: async (org_code, email_id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/add',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            email_id
        };
        return invokeApi(requestObj);
    },

    remove_account: async (org_code, member_id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/remove',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            member_id
        };
        return invokeApi(requestObj);
    },

}


export const OrgConfigServices = {

    enable_ui_config: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/enable_ui_config',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },

    disable_ui_config: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/disable_ui_config',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },

}


export const OrgOrderServices = {

    list_orders: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },

    approve_order: async (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/approve',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            id
        };
        return invokeApi(requestObj);
    },

    reject_order: async (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/reject',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            id
        };
        return invokeApi(requestObj);
    },

    create_order: async (org_code, order_details, license_count) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            order_details,
            license_count
        };
        return invokeApi(requestObj);
    }
}